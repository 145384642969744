// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Query from "../../Query.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as QuestionId from "../../tree/QuestionId.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

async function $$do(param) {
  var vessels = param.vessels;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var s = await Query.Safe.read((newrecord.newAnswers = {
          TAG: "AnyOf",
          _0: "vessel_id",
          _1: vessels
        }, newrecord));
  if (s.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: s._0
          };
  }
  var initial = AppSchema.NewAnswers.updateMany(param.initial, s._0.newAnswers);
  return {
          TAG: "Ok",
          _0: {
            user: param.user,
            vessels: vessels,
            initial: initial,
            final: param.final
          }
        };
}

var FillInitialStatus = {
  $$do: $$do
};

async function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var questions = QuestionId.$$Set.fromArray(AppSchema.Questions.all($$final).map(function (q) {
            return q.id;
          }));
  var levels = LevelId.$$Set.fromArray(AppSchema.Levels.all($$final).map(function (l) {
            return l.id;
          }));
  var newAnswers = AppSchema.NewAnswers.all(initial).filter(function (answer) {
        if (LevelId.$$Set.has(levels, answer.level_id)) {
          return QuestionId.$$Set.has(questions, answer.question_id);
        } else {
          return false;
        }
      });
  var $$final$1 = AppSchema.NewAnswers.updateMany($$final, newAnswers);
  return {
          TAG: "Ok",
          _0: {
            user: param.user,
            vessels: param.vessels,
            initial: initial,
            final: $$final$1
          }
        };
}

var ApplyLocallyKnownState = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.newAnswers = AppSchema.NewAnswers.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncRemoveUnusedNewAnswers", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ReadServerState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.NewAnswers];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var views$1 = [AppSchema.NewAnswers];

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup({
      views: views$1
    });

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncRemoveUnusedNewAnswers",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  FillInitialStatus ,
  ApplyLocallyKnownState ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
